.layout {
    //background: #f0f2f5; // ant layout bg
    background: #fff
}

header {
    display: flex;
    background: #319795;
    height: 48px;
    padding: 0 50px;
    margin: 0;
    a {
        background-color: transparent;
        color: inherit;
        text-decoration: inherit;
        &::before,
         ::after {
            border-color: #e2e8f0;
            overflow-wrap: break-word;
        }
        h1 {
            font-size: 24px;
            font-weight: bolder;
            line-height: 40px;
        }
    }
    .navbar {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin: 0 32px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: 100%;
        }
        li {
            float: left;
        }
        li a {
            display: block;
            color: white;
            text-align: center;
            padding: 0 16px;
            text-decoration: none;
            font-weight: bold;
            font-size: 16px;
            line-height: 48px
        }
        li a:hover {
            background-color: #fff;
            color: #319795;
        }
        .active {
            background-color: #fff;
            color: #319795;
            a {
                color: #319795;
            }
        }
    }
    .center {
        flex: 1 1 0;
        place-self: stretch;
    }
    .right {
        display: flex;
        .account-box,
        .language-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            button {
                font-weight: bold;
                color: #fff;
            }
        }
    }
}

.basic-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #f0f2f5;
    // background: #ffffff;
}

.footer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    // height: 40px;
    height: 0px;
    font-weight: bold;
}