// @tailwind base;
@tailwind components;
@tailwind utilities;
body {
    background-color: #fff;
}

.flex {
    display: flex;
}

.flex__end {
    display: flex;
    justify-content: flex-end;
}

.flex__between {
    display: flex;
    justify-content: space-between;
}

.mrgin__16 {
    margin: 16px;
}

.mrginR__16 {
    margin-right: 16px;
}

.mrginL__16 {
    margin-left: 16px;
}

.mrginT__16 {
    margin-top: 16px;
}

.mrginB__16 {
    margin-bottom: 16px;
}

.mrgin__8 {
    margin: 8px;
}

.mrginR__8 {
    margin-right: 8px;
}

.mrginL__8 {
    margin-left: 8px;
}

.mrginT__8 {
    margin-top: 8px;
}

.mrginB__8 {
    margin-bottom: 8px;
}

.bold {
    font-weight: bold;
}

.left_40 {
    left: 40%;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.pd__16 {
    padding: 16px;
}

.pdTB__16 {
    padding: 16px 0;
}

.pdLR__16 {
    padding: 0 16px;
}

.pd__16 {
    padding: 16px;
}

.pdTB__8 {
    padding: 8px 0;
}

.pdLR__8 {
    padding: 0 8px;
}

.pd__4 {
    padding: 4px;
}

.pdTB__4 {
    padding: 4px 0;
}

.pdLR__4 {
    padding: 0 4px;
}

.border {
    border: 1px solid gray;
}

.border__dot {
    border-style: dotted;
}

.border__dash {
    border-style: dashed;
}

.table-row-change {
    background-color: #d2e1bf;
}
.table-row-change-history {
    background-color: #d3cf9e;
}


.table-row-cont {
    background-color: #e2c788;
}

.table-row-light {
    background-color: #ffffff;
    min-height: 40px;
}

.table-row-dark {
    background-color: #f6f6f6;
}

.table-row-light-custom {
    background-color: #64af8e;
    border-width: 5px;
}

.table-row-red {
    background-color: #fff1f0;
}

.table-row-green {
    background-color: green;
}

.table-row-yellow {
    background-color: #feffe6;
}

.table-row-blue {
    background-color: #e6f7ff;
}

.table-row-orange {
    background-color: #fff7e6;
}

.table-row-purple {
    background-color: #f9f0ff;
}


.table-row-pricing {
    background-color: #ffffff;
    height: 48px;
}
.table-row-history {
    background-color: #ffffff;
    height: 48px;
}

.table-row-reality-warning {
    background-color: #cbe1b6;
    height: 48px;
}



/* ----RenderPackageSize End------- */